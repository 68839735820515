import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const WeiterbildungPage = () => (
  <Layout>
    <SEO title="Weiterbildung" />

    <h1 id="ueberschrift">Informationen zum Lern- &amp; Verbesserungsangebot</h1>
    <Link to="/fdml_5a_weiterbildung-klartext#ueberschrift" title="Detaillierte Informationen zum Lern- &amp; Verbesserungsangebot" style={{fontSize: "1.5em"}}>zum Klartext</Link>

    <p className="poem" style={{marginTop: "0.5em"}}>
      Ein langer Tag mit Stress und Tücken?<br />
      Ein leerer Kopf trotz Eselsbrücken?<br />
      Oder zu voll ob der Termine?<br />
      Zu viele Jobs da „gute Miene“!<br />
      „So viel zu tun – so wenig Zeit“<br />
      Kaum einer ist davon gefeit.<br />
    </p>

    <p className="poem" style={{marginTop: "0.5em"}}>
      Mit Fantasie und einer List<br />
      Kannst auch Du seh‘n was wichtig ist.<br />
      Kannst lernen richtig Platz zu schaffen<br />
      Oder Dich „endlich aufzuraffen“<br />
      Und mit Terminen zu jonglieren<br />
      Und mit and'ren interagieren.<br />
      So wird der Stress vor Schreck ganz blass<br />
      Und Du hast wieder Deinen Spaß!
    </p>
  </Layout>
              )

              export default WeiterbildungPage
